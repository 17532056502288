import React, { useState } from 'react';
import { useDrag } from '@use-gesture/react';

const majorKeys = ["C", "G", "D", "A", "E", "B", "F#", "Db", "Ab", "Eb", "Bb", "F"];
const minorKeys = ["Am", "Em", "Bm", "F#m", "C#m", "G#m", "D#m", "Bbm", "Fm", "Cm", "Gm", "Dm"];
const inversions = ["Root", "1st", "2nd", "3rd"];

const CircleOfFifths = () => {
    const [activeKeys, setActiveKeys] = useState({});
  
    const toggleKey = (key) => {
      setActiveKeys((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    };
  
    const createArcPath = (innerRadius, outerRadius, startAngle, endAngle) => {
      const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
  
      const startInnerX = 200 + innerRadius * Math.cos(startAngle);
      const startInnerY = 200 + innerRadius * Math.sin(startAngle);
      const endInnerX = 200 + innerRadius * Math.cos(endAngle);
      const endInnerY = 200 + innerRadius * Math.sin(endAngle);
  
      const startOuterX = 200 + outerRadius * Math.cos(startAngle);
      const startOuterY = 200 + outerRadius * Math.sin(startAngle);
      const endOuterX = 200 + outerRadius * Math.cos(endAngle);
      const endOuterY = 200 + outerRadius * Math.sin(endAngle);
  
      return `M ${startOuterX} ${startOuterY}
              A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 1 ${endOuterX} ${endOuterY}
              L ${endInnerX} ${endInnerY}
              A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${startInnerX} ${startInnerY}
              Z`;
    };
  
    const createKeySection = (key, index, innerRadius, outerRadius, isActive) => {
      const startAngle = (index / 12) * 2 * Math.PI;
      const endAngle = ((index + 1) / 12) * 2 * Math.PI;
      const angle = (startAngle + endAngle) / 2;
  
      const textX = 200 + ((innerRadius + outerRadius) / 2) * Math.cos(angle);
      const textY = 200 + ((innerRadius + outerRadius) / 2) * Math.sin(angle);
  
      return (
        <g key={key}
        onClick={(a, b, c) => {
            toggleKey(key)
        }}
        onMouseEnter={(event) => {
            const isPressed = event.buttons === 1;
            if(isPressed) {
                toggleKey(key)
            }
        }}
        onTouchStart={(event) => {
          toggleKey(key);
        }}
        onTouchMove={(event) => {
          event.preventDefault(); // Prevent scrolling while touching the SVG
          const touch = event.touches[0];
          const targetElement = document.elementFromPoint(touch.clientX, touch.clientY);
          if (targetElement && targetElement.closest('g.key-section')) {
            const targetKey = targetElement.closest('g.key-section').dataset.key;
            if (targetKey && targetKey !== key) {
              toggleKey(targetKey);
            }
          }
        }}
        className="key-section">
          <path
            d={createArcPath(innerRadius, outerRadius, startAngle, endAngle)}
            fill={isActive ? "lightblue" : "grey"}
          />
          <text x={textX} y={textY} textAnchor="middle" alignmentBaseline="middle" fill="black">
            {key}
          </text>
        </g>
      );
    };
  
    return (
      <svg viewBox="0 0 400 400" className="circle-of-fifths responsive-svg">
        {majorKeys.map((key, index) =>
          createKeySection(key, index, 100, 150, activeKeys[key])
        )}
        {minorKeys.map((key, index) =>
          createKeySection(key, index, 50, 100, activeKeys[key])
        )}
      </svg>
    );
  };
  
  export default CircleOfFifths;